import React, { useEffect, useState } from 'react';
import { usePage, router } from '@inertiajs/react';
import RefreshModal from './RefreshModal';
import Modal from './Modal';
import SecondaryButton from './SecondaryButton';
import GreenButton from './GreenButton';

const VersionInfo = ({hide}) => {
    const { currentVersion: initialVersion } = usePage().props;
    const [currentVersion, setCurrentVersion] = useState(initialVersion);

    const [ newVersionAvailable, setNewVersionAvailable ] = useState(false);

    const checkForAssetUpdates = async () => {
        try {
          const response = await fetch('/asset-version');
          const data = await response.json();
          if (data.version !== currentVersion) {
            //alert('A new version of the site is available. Please refresh the page.');
            //console.log("New version available...");
            setCurrentVersion(data.version);
            // Force reload...
            window.location.reload();
            //setNewVersionAvailable(true);
          }
        } catch (error) {
          console.error('Error checking for asset updates:', error);
        }
      };
    
      useEffect(() => {
        const intervalId = setInterval(checkForAssetUpdates, 60000); // Check every 10 seconds
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }, [currentVersion]);
    

      const closeModal = () => {
        setNewVersionAvailable(false);
      };


      const handleRefresh = (e) => {
        e.preventDefault();
        console.log("refresh pls");
        window.location.reload();
      };      
      
      return (        
        <>
        <p className={`${hide ? 'hidden' : 'block'} text-[0.7em] text-center text-gray-200 pb-[10px]`}>version {currentVersion}</p>

        <Modal show={newVersionAvailable} onClose={closeModal}>
                <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900">
                        There is a new version of this page.
                    </h2>

                    <p className="mt-1 text-sm text-gray-600">
                        Do you want to refresh?
                    </p>

                    <p className="mt-1 text-sm text-gray-600">
                        The site will work best with a refresh.         
                    </p>

                    <div className="mt-6 flex justify-end">
                        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>

                        <GreenButton className="ms-3" onClick={handleRefresh}>
                            Refresh
                        </GreenButton>
                    </div>
                </div>
            </Modal>

        </>
      );

};    

export default VersionInfo;