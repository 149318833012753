import { usePage, Link } from '@inertiajs/react';
import FooterNavLink from '@/Components/FooterNavLink';
import TimeZoneSetter from '@/Components/TimeZoneSetter';
import VersionInfo from '@/Components/VersionInfo';

export default function Main({ auth, children }) {

    const { build_version, country } = usePage().props;

    var l = 0;
    if(auth.user) {
        l = auth.user.l;
    }

    const isSendPage = window.location.pathname.includes('/send') || window.location.pathname.includes('/edit');    

    return (
        <div className="bg-white text-black">
        <div className="relative min-h-screen flex flex-col items-center justify-start selection:bg-pink selection:text-white">
            <div className="relative w-full max-w-[700px] px-1 md:px-0">
                <header className="items-center pt-[8px] md:pt-[8px] flex">
                    <div className="flex-2">
                        <h1>
                                <Link
                                    href={route('home')}
                                    className="text-[22px] tracking-[0.5px] rounded-md text-pink p-[15px] pl-[3px] pr-[3px] md:pl-[2px] ring-1 ring-transparent transition hover:pink/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    letters to crushes
                                </Link>                                    
                        </h1>
                    </div>
                    <nav className="text-[12px] flex flex-1 justify-end mr-[3px]">
                            <>
                                <Link
                                    href={route('letter.send')}
                                    className="rounded-md text-center box-content pt-[4px] pl-[4px] pr-[4px] md:text-center leading-[1.45em] mr-[2px] w-[50px] md:w-[75px] md:mr-[4px] text-black ring-1 ring-transparent transition hover:text-black/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    send
                                </Link>
                                <Link
                                    href={route('more')}
                                    className="rounded-md text-center box-content pt-[4px] pl-[4px] md:text-center leading-[1.45em] mr-[0px] w-[50px] md:w-[75px] text-black ring-1 ring-transparent transition hover:text-black/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline active:underline"
                                >
                                    more
                                </Link>
                            </>                                
                    </nav>
                </header>

                <main className="pt-[16px] px-[30px] w-full">
                    <div className="w-full max-w-[500px] text-left mx-auto">
                        {children}
                    </div>
                </main>

                <footer className={`pt-16 text-center text-sm text-black ${isSendPage ? 'hidden' : ''}`}>
                    {/*

                        logged out:
                        - about
                        - login
                        terms of use
                        privacy policy
                        - search
                        - feedback

                        normal users (mod_level = 0):
                        - about
                        + logout
                        + bookmarks
                        terms of use
                        privacy policy
                        - search
                        - feedback
                        
                        mod users (mod_level > 1):
                        - about
                        + logout
                        + bookmarks
                        queue
                        popular
                        block
                        comments
                        mod letters
                        - search
                        - feedback
                    */}

                    <FooterNavLink
                        href={route('about')}
                        >
                        about
                    </FooterNavLink>

                    {auth.user ? (
                            <>
                            <FooterNavLink
                                href={route('logout')}
                                method="post"
                                as="button"
                                >
                                    logout
                            </FooterNavLink>

                            <FooterNavLink
                                    href={route('bookmarks')}
                                    >
                                    bookmarks
                            </FooterNavLink>   

                            {l === 0 && (
                                <>
                                <FooterNavLink
                                    href={route('terms')}
                                    >
                                terms of use
                                </FooterNavLink>
                                <FooterNavLink
                                    href={route('privacy')}
                                >
                                    privacy policy
                                </FooterNavLink>
                                </>
                            )}

                            {l > 0 && (
                                <>
                                <FooterNavLink
                                    href={route('queue.index')}
                                    >
                                    queue
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('popular.index')}
                                    >
                                    popular
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('block')}
                                    >
                                    block
                                </FooterNavLink>                            

                                <FooterNavLink
                                    href={route('comments')}
                                    >
                                    comments
                                </FooterNavLink>     

                                <br />

                                <FooterNavLink
                                    href={route('mod-letters')}
                                    >
                                    mod letters
                                </FooterNavLink>     

                                </>
                            )}


                            {l == 2 && (
                                <>
                                <FooterNavLink
                                    href={route('activity.index')}
                                    >
                                    activity
                                </FooterNavLink>     

                                <FooterNavLink
                                    href={route('require-email.index')}
                                    >
                                    require email
                                </FooterNavLink>     

                                </>
                            )}
                            </>                                    
                        ) : (
                            <>
                                <FooterNavLink
                                    href={route('login')}
                                >
                                    login
                                </FooterNavLink>
                                <FooterNavLink
                                    href={route('terms')}
                                >
                                    terms of use
                                </FooterNavLink>
                                <FooterNavLink
                                    href={route('privacy')}
                                >
                                    privacy policy
                                </FooterNavLink>
                            </>
                        )}

                        <FooterNavLink
                            href={route('search.index')}
                        >
                            search
                        </FooterNavLink>

                        <FooterNavLink
                            href={route('feedback.index')}
                        >
                            feedback
                        </FooterNavLink>


                </footer>
                <VersionInfo hide={isSendPage}></VersionInfo>

                {(!isSendPage && country == "PH") && (
                    <>
                    <p className="text-center text-gray-300 font-light grayscale leading-[1rem]">
                        <a href="https://lihamngpuso.com" target="_blank" className="no-underline">
                        <span className="text-[0.6em]">LTC</span><svg xmlns="http://www.w3.org/2000/svg" 
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className={`relative inline w-[14px] mx-[2px]`}
                          >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                      </svg>
                        <span className="text-[0.6em]">PH
                            </span> <br />
                        <span className="text-[0.7em] tracking-[0.075em] font-light underline">lihamngpuso.com</span></a>           
                    </p>
                    </>
                )}

            </div>
        </div>
        <TimeZoneSetter />
    </div>
    );
}
