import { useEffect } from 'react';

const TimeZoneSetter = () => {
    useEffect(() => {

        if(isUserTimeZoneSet()) {
            //console.log("Time zone already set.");
            return;
        }

        // Get the timezone offset
        //const offset = new Date().getTimezoneOffset() / 60;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Set the cookie for userTimeZone
        document.cookie = `userTimeZoneSet=true; path=/; max-age=${60 * 60 * 24 * 365}; SameSite=Lax`;

        axios.post('/timezone', {
            timeZone: timeZone
          }).then(response => {
            //console.log('Time zone set successfully');
          }).catch(error => {
            //console.error('Error setting time zone', error);
          });        
        

    }, []);

    function isUserTimeZoneSet() {
        const name = "userTimeZoneSet=";
        const decodedCookies = decodeURIComponent(document.cookie);
        const cookiesArray = decodedCookies.split(';');
        
        for(let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length) === "true";
            }
        }
        return false;
    }    

    return null; // No UI element, just runs the effect
};

export default TimeZoneSetter;