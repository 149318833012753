import { Link } from '@inertiajs/react';

export default function FooterNavLink({ active = false, className = '', children, ...props }) {
    return (
        <Link
            {...props}
            className={
                'rounded-md px-[5px] mt-[5px] text-[0.7em] text-black ring-1 ring-transparent transition hover:text-black/70 hover:no-underline focus:outline-none focus-visible:ring-[#FF2D20] underline ' +
                className
            }
        >
            {children}
        </Link>
    );
}
